import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { QuillModule } from 'ngx-quill';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { TourNgBootstrapModule } from 'ngx-ui-tour-ng-bootstrap';
import { ConfirmationService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityScriptComponent } from './clarity-script/clarity-script.component';
import { ClubExploreComponent } from './clubs/club-explore/app-club-explore.component';
import { CreateRoomButtonModal } from './clubs/club-page/common/create-room-modal-button/create-room-modal-button.component';
import { DeleteClubModalComponent } from './clubs/club-page/common/delete-club-modal/delete-club-modal.component';
import { RoomNavigationItemComponent } from './clubs/club-page/common/room-navigation-item/room-navigation-item.component';
import { RoomNavigationComponent } from './clubs/club-page/common/room-navigation/room-navigation.component';
import { ClubMoreInfoModalComponent } from './clubs/components/app-club-more-info-modal/app-club-more-info-modal.component';
import { ClubNavigationComponent } from './clubs/components/club-navigation/club-navigation.component';
import { CreateClubModalButtonComponent } from './clubs/components/create-club-modal-button/create-club-modal-button.component';
import { GearEditClubComponent } from './clubs/components/gear-edit-club/gear-edit-club.component';
import { FiltersModule } from './filters/filters.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { OnBoardingCompleteProfileModule } from './home/components/onboarding-complete-profile/onboarding-complete-profile.module';
import { ResetPasswordModalComponent } from './home/components/reset-password-modal/reset-password-modal.component';
import { TermsOfUseContentComponent } from './home/components/terms-of-use-content/terms-of-use-content.component';
import { TermsOfUseModalComponent } from './home/components/terms-of-use-modal/terms-of-use-modal.component';
import { TermsOfUseComponent } from './home/pages/terms-of-use/terms-of-use.component';
import { ActivateComponent } from './login/activate.component';
import { LoginComponent } from './login/login.component';
import { Oauth2DepartureComponent } from './login/oauth2/oauth2-departure/oauth2-departure.component';
import { Oauth2RedirectComponent } from './login/oauth2/oauth2-redirect/oauth2-redirect.component';
import { SamlRedirectComponent } from './login/saml/saml-redirect.component';
import { VerifyComponent } from './login/verify.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AccountsService } from './services/identity/accounts.service';
import { CommunityService } from './services/identity/community.service';
import { OrganizationService } from './services/identity/organization.service';
import { ProfileService } from './services/identity/profile.service';
import { TokenVerifierService } from './services/identity/token-verifier.service';
import { UserTypeService } from './services/identity/user-type.service';
import { PwaService } from './services/pwa/pwa.service';
import { CacheService } from './services/shared/cache.service';
import { DataStorageService } from './services/shared/data-storage.service';
import { ErrorHandlerService } from './services/shared/error-handler.service';
import { MonitoringService } from './services/shared/logging.service';
import { PostsSignalrService } from './services/signalr/posts-signalr.service';
import { NoSignalComponent } from './shared/components/no-signal/no-signal.component';
import { PwaA2hsComponent } from './shared/components/pwa-a2hs/pwa-a2hs.component';
import { PwaAppStoreComponent } from './shared/components/pwa-app-store/pwa-app.store.component';
import { PwaUpdateComponent } from './shared/components/pwa-update/pwa-update.component';
import { SharedModule } from './shared/shared.module';
import { PermissionsState } from './states/permissions/permissions.state';
import { UserModule } from './user/user.module';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            timeOut: 5000,
        }),
        SharedModule,
        FiltersModule,
        UserModule,
        QuillModule.forRoot(),
        NgxSpinnerModule,
        ServiceWorkerModule.register('/ngsw-worker.js?version=13', {
            enabled: environment.features.pwa,
            registrationStrategy: 'registerImmediately',
        }),
        NgxsModule.forRoot([PermissionsState], {
            developmentMode: !environment.production,
        }),
        RxReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: environment.settings.defaultLanguage,
        }),
        SidebarModule,
        OnBoardingCompleteProfileModule,
        TourNgBootstrapModule.forRoot(),
    ],
    declarations: [
        AppComponent,
        NavMenuComponent,
        TermsOfUseComponent,
        TermsOfUseContentComponent,
        TermsOfUseModalComponent,
        ResetPasswordModalComponent,
        LoginComponent,
        VerifyComponent,
        ActivateComponent,
        PwaUpdateComponent,
        PwaA2hsComponent,
        PwaAppStoreComponent,
        NoSignalComponent,
        Oauth2RedirectComponent,
        Oauth2DepartureComponent,
        SamlRedirectComponent,
        ClubNavigationComponent,
        RoomNavigationComponent,
        ClarityScriptComponent,
        ClubMoreInfoModalComponent,
        RoomNavigationItemComponent,
        CreateClubModalButtonComponent,
        GearEditClubComponent,
        DeleteClubModalComponent,
        CreateRoomButtonModal,
        ClubExploreComponent,
    ],
    providers: [
        AccountsService,
        CacheService,
        CommunityService,
        UserTypeService,
        OrganizationService,
        ProfileService,
        DataStorageService,
        NgxSpinnerService,
        PwaService,
        PostsSignalrService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: Window, useValue: window },
        ConfirmationService,
        TokenVerifierService,
        MonitoringService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
