<div
    *ngIf="showPage"
    id="main"
    [ngClass]="{
        'container-fluid': isAuthenticatedWithTermsOfUseAccepted(),
        'login-background full-height': !isAuthenticatedWithTermsOfUseAccepted() && !isPublicPage
    }"
>
    <app-top-bar-public *ngIf="isPublicPage && !isAuthenticatedWithTermsOfUseAccepted()"></app-top-bar-public>

    <app-top-bar *ngIf="isAuthenticatedWithTermsOfUseAccepted()" (createInteractionEvent)="onCreateInteractionClick()"></app-top-bar>
    <app-nav-menu *ngIf="isAuthenticatedAndCanUsePlatform()"></app-nav-menu>
    <div class="p-d-flex">
        <club-navigation
            *ngIf="isAuthenticatedAndCanUsePlatform() && canViewClubs"
            class="club-navigation club-navigation-expanded"
        ></club-navigation>
        <div class="main-container">
            <app-no-signal *ngIf="isOffline"></app-no-signal>
            <router-outlet *ngIf="!isOffline"></router-outlet>
        </div>
    </div>
</div>

<ngx-spinner
    *ngIf="!showPage"
    size="large"
    color="{{ environment.settings.code !== 'NuclearValleyLink' ? '#eb588a' : '#45e4ba' }}"
    type="ball-scale-ripple"
    [fullScreen]="true"
></ngx-spinner>
<div appTokenRefresher></div>

<tour-step-template></tour-step-template>
<app-clarity-script></app-clarity-script>
