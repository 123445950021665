// clarity-script.component.ts
import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-clarity-script',
    template: '',
})
export class ClarityScriptComponent implements OnInit {
    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        if (environment.production) {
            this.addClarityScript();
        }
    }

    private addClarityScript(): void {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window,document,"clarity","script","ij43ce2d97");
    `;

        this.renderer.appendChild(document.head, script);
    }
}
